<template>
  <div class="bg-gray-50 py-16">
    <section class="platform-and-guest-images max-w-md w-full mx-auto flex items-center justify-center">
      <div class="relative w-40 h-40 rounded-full overflow-hidden mx-3">
        <template v-if="guest.imageUrl">
          <img :src="guest.imageUrl" class="w-full h-full object-cover" :alt="guest.name">
        </template>
        <template v-else>
          <img src="/img/test_image.01d18838.png" class="w-full h-full object-cover" alt="">
            <span class="absolute inset-x-0 bottom-0 py-1 flex items-center justify-center bg-black bg-opacity-75 text-gray-200">
              {{ guest.name }}
            </span>
        </template>
      </div>

      <div class="relative w-40 h-40 rounded-full overflow-hidden mx-3">
        <template v-if="show.imageUrl">
          <img :src="show.imageUrl" class="w-full h-full object-cover" :alt="show.name">
        </template>
        <template v-else>
          <img src="/img/test_image.01d18838.png" class="w-full h-full object-cover" :alt="show.name">
            <span class="absolute inset-x-0 bottom-0 py-1 flex items-center justify-center bg-black bg-opacity-75 text-gray-200">
              {{ show.name }}
            </span>
        </template>
      </div>
    </section>

    <section class="mt-12 max-w-md w-full mx-auto px-4 sm:px-0">
      <h1 class="text-4xl sm:text-5xl font-bold leading-tight flex">
        <span>Congrats!</span>
        <span class="ml-2">🎉</span>
      </h1>

      <h2 class="mt-4 text-2xl sm:text-3xl font-bold">
        We'll let you know when <span class="text-transparent bg-clip-text bg-gradient-to-br from-customPurple to-pink-300">{{ requestedProfile.name }}</span> confirms your Booking
      </h2>

      <div class="mt-8 sm:mt-12 bg-white shadow-xl rounded-xl border border-gray-300">
        <div class="border-b border-gray-300">
          <div class="px-6 py-6 sm:px-16 sm:py-8">
            <span class="text-gray-500 leading-0">Receipt</span>
          </div>
        </div>
        <div class="py-8 px-6 sm:px-16 text-sm">
          <div class="space-y-6">
            <div class="flex items-center justify-between">
              <span class="font-semibold text-black">Booking Fee</span>
              <span class="text-gray-700">${{ (booking.total_price/100).toFixed(2) }}</span>
            </div>
            <div v-if="booking.guestio_cash" class="flex items-center justify-between">
              <span class="font-semibold text-black">Guestio Cash Credit</span>
              <span class="text-gray-700">- ${{ (booking.guestio_cash / 100).toFixed(2) }}</span>
            </div>
            <div class="flex items-center justify-between">
              <span class="font-semibold text-black">Amount charged</span>
              <span class="text-gray-700">${{ (booking.price / 100).toFixed(2) }}</span>
            </div>
            <div v-if="booking.price > 0" class="flex items-center justify-between">
              <span class="font-semibold text-black">Card charged</span>
              <span class="text-gray-700 uppercase">{{ booking.payment_method_details.last4 }} {{ booking.payment_method_details.brand }}</span>
            </div>
            <div class="flex items-center justify-between">
              <span class="font-semibold text-black">{{ initiator == 'show' ? 'Podcaster' : 'Platform' }}</span>
              <span class="text-gray-700">{{ requestedProfile.name }}</span>
            </div>

            <div v-if="booking.price > 0">
              <a :href="downloadPdfUrl" class="text-sm hover:underline text-pink-500 underline" target="_blank" rel="noopener">Download</a>
            </div>
          </div>

          <div class="mt-12 text-center">
            <router-link :to="{name: 'Activity', params: {bookingId: booking.id}}" class="bg-indigo-gradiant rounded-full text-white font-bold px-10 py-4 whitespace-no-wrap">
              Go to booking page
            </router-link>
          </div>
        </div>
      </div>

      <div class="mt-12">
        <span class="text-sm text-gray-700">
          Have a question? <a href="mailto:support@guestio.com" class="open-intercom text-pink-500 hover:underline">Contact support</a>
        </span>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: "BookingSuccess",

  props: {
    booking: { type: Object, required: true },
  },

  computed: {
    user() {
      return this.$store.getters['auth/getUser']
    },

    initiator() {
      return this.booking.initiator ? this.booking.initiator : 'show'
    },

    requestedProfile() {
      return this.initiator == 'show' ? this.guest : this.show;
    },

    guest() {
      return this.booking.guest
    },

    show() {
      return this.booking.show
    },

    downloadPdfUrl() {
      return `${process.env.VUE_APP_API_ENDPOINT}/payments/${this.booking.id}/download`
    },
  },

  async created() {
    if (! this.booking) {
      this.$router.push({ name: 'Home' })
    }

    this.$store.dispatch('auth/getUser')
  },
};
</script>

